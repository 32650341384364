import { graphql } from "gatsby";
import React, { useEffect, useRef } from "react";
import { strToTitleCase } from "../functions";
import styled, { css } from "styled-components";
import { useRenderChart } from "../components/charts/chart";
import SEO from "../components/seo";
import { device } from "../components/breakpoint";

const Container = styled.div`
  margin: 0 auto;
  max-width: 800px;
  ${device.sm`
    margin-top: 55px;
  `}
  a {
    text-decoration: underline;
    text-decoration-color: #000;
  }
`;

const AirbnbOptimization = ({ data, pageContext }) => {
  const { shortTermRentalSetAverages } = data;
  const { name, shortTermRentalGroup } = shortTermRentalSetAverages;
  const { charts } = shortTermRentalGroup;
  const {
    person_capacity,
    rating_cleanliness,
    price_rate,
    photos,
    beds,
    bathrooms,
    review_count,
    star_rating,
  } = shortTermRentalGroup;
  const cityTitle = strToTitleCase(name);
  return (
    <div>
      <SEO
        title={`Airbnb Statistics ${cityTitle}: Averages, Revenue, and Pricing Guide.`}
        description={`Airbnb data analysis and statistics ${cityTitle}. Hosts charge an average of ${price_rate} per day.`}
      />

      <a
        target="_blank"
        href={`https://buy.stripe.com/6oE3ec2KJ9NldFe289`}
        className="md:hidden"
      >
        <div
          className="z-10 fixed bottom-0 w-full grid items-center  justify-center text-white bg-black  p-4 
        hover:bg-white  hover:text-black
        "
        >
          👇 Optimize Your Airbnb Listing
          <br />
          <span className="pt-2">👉 Book A Consult Now! </span>
        </div>
      </a>

      <Container className="">
        <div className="pr-4 pl-4  md:p-0">
          <div className="z-10 fixed left-0 top-20 hidden md:block">
            <a
              href={"https://buy.stripe.com/6oE3ec2KJ9NldFe289"}
              target={"_blank"}
            >
              👇 Optimize Airbnb Listing
            </a>
          </div>

          <div className="col-span-8">
            <h1 className="text-3xl font-bold text-center">
              Airbnb Statistics {cityTitle}: Averages, Revenue, and Pricing
              Guide.
            </h1>
            <p className="mt-4 text-xl">
              In the table below you will find the following data.
            </p>
            <ol className="mt-4 text-lg">
              <li className="mt-4"> Average Monthly Revenue </li>
              <li className="mt-4"> Average Yearly Revenue</li>
              <li className="mt-4"> Average Price Rate</li>
              <li className="mt-4"> Average Bedrooms</li>
              <li className="mt-4"> Average Bathrooms</li>
              <li className="mt-4"> Average Star Rating</li>
              <li className="mt-4">Average Review Count</li>
              <li className="mt-4">
                <ul className="mt-4">
                  <li>
                    <h2 className="text-xl text-center font-medium">
                      What is the average Airbnb data for {cityTitle}
                    </h2>
                  </li>
                  <li className="grid grid-flow-row-dense h-96 mt-4">
                    <ChartItem chart={charts[8]} city={name} />
                  </li>
                </ul>
              </li>
              <li className="mt-4">
                Price your Airbnb listing relative to other listings with the
                same bedroom and bathroom configuration. A good guage is the
                <a target="_blank" href={`/${name.split(" ").join("-")}`}>
                  {" "}
                  average price rate, bedrooms and bathrooms{" "}
                </a>{" "}
                for the overall market.
              </li>

              <li className="mt-4">
                View the
                <a
                  target="_blank"
                  href={`/airbnb-guide/${name.split(" ").join("-")}`}
                >
                  {" "}
                  Airbnb guide
                </a>{" "}
                for {cityTitle}
              </li>
            </ol>
          </div>
        </div>
      </Container>
    </div>
  );
};

const ChartItem = ({ chart, city }) => {
  const ref = useRef();
  useRenderChart(chart, ref, city);
  return <div ref={ref} />;
};

export default AirbnbOptimization;

export const pageQuery = graphql`
  query ($cityName: String!) {
    shortTermRentalSetAverages(name: { eq: $cityName }) {
      shortTermRentalGroup {
        beds
        bathrooms
        access
        additional_house_rules
        amenities
        weekly_price_factor
        url
        transit
        total_price
        star_rating
        satisfaction_guest
        room_type
        room_and_property_type
        review_score
        review_count
        response_time
        response_rate
        rating_value
        rating_location
        rating_communication
        rating_cleanliness
        rating_checkin
        rating_accuracy
        price_rate_type
        price_rate
        photos
        person_capacity
        notes
        neighborhood_overview
        name
        monthly_price_factor
        longitude
        interaction
        latitude
        description
        city
        charts
      }
      name
    }
  }
`;
